<template>
  <BaseList
    :list-query="list"
    :name-map="nameMap"
    :sort="sort"
    route="crossform"
    locale-section="pages.crossForms"
  >
    <template v-slot:extra="{ element: { aspect, form } }">
      <img
        :src="
          // FIXME: make url configurable, store images locally?
          `https://www.runinskrifter.net/img/crosses/${aspect}/${aspect}${form}.png`
        "
        class="list-image"
      />
    </template>
  </BaseList>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "CrossFormsList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      list: gql`
        query list {
          list: crossForms {
            aspect
            form
            id
          }
        }
      `
    };
  },
  methods: {
    nameMap(element) {
      return {
        name: element.aspect + " " + element.form,
        ...element
      };
    },
    sort: ({ aspect1, form1 }, { aspect2, form2 }) =>
      aspect1 == aspect2 ? form1 < form2 : aspect1.localeCompare(aspect2, "sv")
  }
};
</script>
